import 'react-day-picker/lib/style.css';
import '../styles/globals.css'
import "react-placeholder/lib/reactPlaceholder.css";
import React from 'react'
import {appWithTranslation} from "next-i18next";
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import dayjs from 'dayjs'
import {createTheme, ThemeProvider} from "@material-ui/core/styles";
import {colors} from '../styles/Colors';

const theme = createTheme({
    palette: {
        primary: {
            main: colors.orange,
        },
    },
});


function MyApp({Component, pageProps}) {
    dayjs.extend(utc)
    dayjs.extend(timezone)

    return (
        <ThemeProvider theme={theme}>
            <Component {...pageProps} />
        </ThemeProvider>
    )

}

export default appWithTranslation(MyApp)
